import { REMINDERS } from './reminder';

/** @typedef {REMINDERS[Number] & { attrs?: { disabled?: Boolean, selected?: Boolean } } | { label: 'Select Reminder', value: 'placeholder', attrs?: { disabled?: true, selected?: Boolean } }} ReminderOption */

/**
 * Compose timezone options for a select input dialog.
 * @returns {ReminderOption[]}
 */
export const useReminderOptions = () => {
    /** @type {ReminderOption} */
    const prompt = {
        label: 'Select Reminder',
        value: 'placeholder',
        attrs: { disabled: true },
    };
    /** @type {ReminderOption[]} */
    const options = REMINDERS.map(({ label, value }) => ({
        label,
        value,
        attrs: {},
    }));
    return [prompt, ...options];
};
