<template>
    <FormSection
        class="pt-6 mt-6"
        v-bind="{ title, description, grid, span }"
    >
        <template #title><slot name="title"></slot></template>
        <template #description><slot name="description"></slot></template>
        <template #default><slot></slot></template>
    </FormSection>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import FormSection from '@/components/forms/partials/FormSection.vue';

    // This Form Component exists to handle a grid of 4 across
    // We can extend the FormSection component to handle overrides
    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'HierarchyFormSection',
        components: {
            FormSection,
        },
        props: {
            /** Form section title. */
            title: {
                /** @type {V.PropType<String>} */
                type: String,
                default: null,
            },
            /** Form section description. */
            description: {
                /** @type {V.PropType<String>} */
                type: String,
                default: null,
            },
            /** Array of tailwindcss grid classes, used to style the content grid. */
            grid: {
                /** @type {V.PropType<String[]>} */
                type: Array,
                default: () => [
                    'mt-6',
                    'grid',
                    'grid-cols-1',
                    'gap-y-6',
                    'gap-x-0',
                    'sm:grid-cols-4',
                ],
            },
            /** Array of span classes, used to style the section separator. */
            span: {
                /** @type {V.PropType<String[]>} */
                type: Array,
                default: () => ['col-span-12', 'mt-6'],
            },
        },
    });
</script>
