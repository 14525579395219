import { TIMEZONES, LEGACY_TIMEZONES } from './timezones';

/** @typedef {{ label: TIMEZONES[number] | LEGACY_TIMEZONES[number] | "Select Timezone", value: TIMEZONES[number] | LEGACY_TIMEZONES[number] | "placeholder", attrs: any }} TimezoneOption */

/**
 * Get supported timezones (or a fallback).
 */
export const getTimezones = () => {
    // @ts-ignore
    if (typeof Intl.supportedTimeZones === 'function') {
        // Use browser timezones.
        // @ts-ignore
        return Intl.supportedTimeZones();
    } else {
        // Use fallback.
        return TIMEZONES;
    }
};

/**
 * Get legacy timezones.
 */
export const getLegacyTimezones = () => {
    const supported = getTimezones();
    return LEGACY_TIMEZONES.filter((z) => !supported.includes(z));
};

/**
 * Compose timezone options for a select input dialog.
 * @returns {TimezoneOption[]}
 */
export const useTimezoneOptions = () => {
    /** @type {TimezoneOption} */
    const prompt = {
        label: 'Select Timezone',
        value: 'placeholder',
        attrs: { disabled: true, selectable: false },
    };
    /** @type {TimezoneOption[]} */
    const validOptions = getTimezones().map((timezone) => ({
        label: timezone,
        value: timezone,
        attrs: { selectable: true },
    }));
    /** @type {TimezoneOption[]} */
    const legacyOptions = getLegacyTimezones().map((timezone) => ({
        label: timezone,
        value: timezone,
        attrs: { disabled: true, selectable: false },
    }));
    return [prompt, ...validOptions, ...legacyOptions];
};

/**
 * Return the value for a given timezone if it's valid.
 * @param {String} value Timezone.
 * @returns {String} Timezone validated.
 */
export const useTimezoneIfValid = (value) => {
    const isSupportedTimezone = getTimezones().includes(value);
    // @ts-ignore
    const isLegacyTimezone = getLegacyTimezones().includes(value);
    const isSpecialTimezoneKeyword = ['placeholder'].includes(value);
    return isSupportedTimezone || isLegacyTimezone || isSpecialTimezoneKeyword
        ? value
        : 'placeholder';
};

export default {
    Timezones: getTimezones(),
    useTimezoneIfValid,
    useTimezoneOptions,
};
