import { TEMPERATURES } from './temperatures';

/** @typedef {TEMPERATURES[Number] & { attrs?: { disabled?: Boolean, selected?: Boolean } } | { label: 'Select Temperature', value: 'placeholder', attrs?: { disabled?: true, selected?: Boolean }}} TemperatureOption */

/**
 * Compose timezone options for a select input dialog.
 * @returns {TemperatureOption[]}
 */
export const useTemperatureOptions = () => {
    /** @type {TemperatureOption} */
    const prompt = {
        label: 'Select Temperature',
        value: 'placeholder',
        attrs: { disabled: true },
    };
    /** @type {TemperatureOption[]} */
    const options = TEMPERATURES.map(({ label, value }) => ({
        label,
        value,
        attrs: {},
    }));
    return [prompt, ...options];
};
